"use client";

import React, { useEffect } from "react";
import { useState } from "react";
import { printType, product } from "@prisma/client";
import { toast } from "sonner";
import { usePathname, useRouter } from "next/navigation";
import { LandingPageCatalogPreview } from "@/components/LandingPageCatalogPreview";
import LikeButton from "@/components/LikeButton";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronUpDownIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { ProductWithLikes } from "@/interfaces/responses/catalog.interface";
import { useCart } from "./CartContext";
export default function ProductComponent({
  product,
  printTypes,
  relatedProducts,
  setProductModal
}: {
  product: ProductWithLikes;
  printTypes?: {
    Posters: printType[];
    "Stretched Canvas": printType[];
  };
  relatedProducts: (product & {
    liked?: boolean;
  })[];
  setProductModal?: React.Dispatch<React.SetStateAction<product | undefined>>;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const [posterOrCanvas, setPosterOrCanvas] = useState<string>(printTypes ? Object.keys(printTypes)[0] : null);
  const [selectedPrintType, setSelectedPrintType] = useState<printType | null>(printTypes ? printTypes[Object.keys(printTypes)[0]]?.[0] : null);
  useEffect(() => {
    if (printTypes) {
      setPosterOrCanvas(Object.keys(printTypes)[0]);
      setSelectedPrintType(printTypes[Object.keys(printTypes)[0]]?.[0] || null);
    }
  }, [printTypes]);
  const [loading, setLoading] = useState(false);
  console.log("selectedPrintType", printTypes, selectedPrintType);
  return <>
      <div className="bg-transparent">
        <div className="pb-16 pt-6 sm:pb-24">
          <nav aria-label="Breadcrumb" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"></nav>
          <div className="mx-auto mt-8 max-w-2xl px-2 sm:px-4 md:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
              <div className="lg:col-span-5 lg:col-start-8">
                <div className="flex justify-between">
                  <div className="flex flex-col gap-2">
                    <h1 className=" text-xl font-medium text-gray-900">
                      {product.title}
                    </h1>
                    <div className="flex flex-row gap-1">
                      {selectedPrintType && selectedPrintType?.productPrice && <p className="text-3xl font-medium text-gray-900">
                          ${(selectedPrintType.productPrice * 1.0).toFixed(2)}
                        </p>}
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-1 ">
                    <LikeButton {...product} data-sentry-element="LikeButton" data-sentry-source-file="ProductComponent.tsx" />

                    <CopyButton product={product} data-sentry-element="CopyButton" data-sentry-source-file="ProductComponent.tsx" />
                  </div>
                </div>
              </div>

              {/* Image gallery */}
              <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                <h2 className="sr-only">Images</h2>

                <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 lg:gap-8">
                  <img alt={product.title} src={product.imageUrl} className={`transition-opacity duration-300 ease-in-out lg:col-span-2 lg:row-span-2 `} onLoad={() => setLoading(false)} onError={() => setLoading(false)} />
                </div>
              </div>

              <div className="mt-8 lg:col-span-5">
                {/* Type picker */}
                <div className="mt-8">
                  {/* Choose a print size */}
                  {printTypes && printTypes[posterOrCanvas] ? <>
                      <div className="flex items-center justify-between">
                        <h2 className="text-sm font-medium text-gray-900">
                          Print Type
                        </h2>
                      </div>

                      <fieldset aria-label="Choose a print type" className="mt-2">
                        <select value={posterOrCanvas} onChange={e => {
                      setPosterOrCanvas(e.target.value);
                      setSelectedPrintType(printTypes[e.target.value]?.[0]);
                    }} className="mt-2 block w-full appearance-none rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-600 sm:text-sm sm:leading-6">
                          {Object.keys(printTypes).map(printType => <option key={printType} value={printType}>
                              {printType.replace("Posters", "Poster")}
                              {/* <ChevronUpDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                               /> */}
                            </option>)}
                        </select>
                      </fieldset>

                      <div className="flex items-center justify-between pt-4">
                        <h2 className="text-sm font-medium text-gray-900">
                          Size
                        </h2>
                      </div>
                      <fieldset aria-label="Choose a size" className="mt-2">
                        <select id="location" name="location" defaultValue="Canada" className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-600 sm:text-sm sm:leading-6" onChange={e => setSelectedPrintType(printTypes[posterOrCanvas].find(p => p.id == parseInt(e.target.value)))}>
                          {printTypes[posterOrCanvas].map((pType: printType) => <option key={pType.prodigiSku} value={pType.id}>
                                {pType.heightInches}x{pType.widthInches}
                                {" inches"} - ${pType.productPrice.toFixed(2)}
                              </option>)}
                        </select>
                      </fieldset>
                      <button type="submit" className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-8 py-3 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2" onClick={e => {
                    e.preventDefault();
                    if (loading) return;
                    setLoading(true);
                    if (!selectedPrintType) {
                      toast.error("No size selected");
                      return;
                    }
                    // fetch the /api/shop/cart endpoint
                    fetch("/api/shop/cart", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      body: JSON.stringify({
                        productUuid: product.uuid,
                        printTypeId: selectedPrintType.id ?? printTypes[0].id,
                        quantity: 1
                      })
                    }).then(response => response.json()).then(data => {
                      console.log("Added to cart:", data);
                      setLoading(false);
                      toast.success("Added to cart!");
                    }).catch(error => {
                      console.error("Error adding to cart:", error);
                    });
                  }}>
                        {loading ? "Adding... " : "Add to cart"}
                      </button>
                    </> : <div className="text-sm text-gray-600">
                      Unfortunately, we do not have any inventory remaining for
                      this artwork. Please check back in later or write to us at
                      support@evoke.art.{" "}
                    </div>}
                </div>

                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                  {[{
                  question: "Description",
                  answer: product.description
                }, {
                  question: "Tags",
                  answer: product.tags?.join(", ")
                }, {
                  question: "Print Details",
                  answer: <ul>
                          <li>Suitable for artwork and photographs.</li>
                          <li>Fulfilled globally.</li>
                          <li>Paper weight: 170gsm (5.0 oz/yd²).</li>
                          <li>Printing method: Aqueous inks.</li>
                          Note: Prints sized 200mm (7.9 inches) or smaller will
                          be sent in an envelope; larger sizes will be sent in a
                          cardboard tube.
                        </ul>
                }].map(faq => <Disclosure key={faq.question} as="div" className="pt-6" defaultOpen={faq.question === "Description"}>
                      <dt>
                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <PlusIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                            <MinusIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                          </span>
                        </DisclosureButton>
                      </dt>
                      <DisclosurePanel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">
                          {faq.answer}
                        </p>
                      </DisclosurePanel>
                    </Disclosure>)}
                </dl>
              </div>
            </div>
            {relatedProducts && relatedProducts.length > 0 && <div>
                <h1 className="py-8 text-2xl font-bold">Similar Art</h1>

                <LandingPageCatalogPreview data={relatedProducts} setProductModal={setProductModal} />
              </div>}
          </div>
        </div>
      </div>
    </>;
}
function CopyButton({
  product
}) {
  const handleCopy = () => {
    const url = `${window.location.origin}/product/${product.titleSlug}-${product.id}`;
    navigator.clipboard.writeText(url).then(() => {
      toast.success("Link copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy link");
    });
  };
  return <button type="button" onClick={handleCopy} className="" data-sentry-component="CopyButton" data-sentry-source-file="ProductComponent.tsx">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" data-sentry-element="svg" data-sentry-source-file="ProductComponent.tsx">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" data-sentry-element="path" data-sentry-source-file="ProductComponent.tsx" />
      </svg>
    </button>;
}