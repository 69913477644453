"use client";

import React, { useState } from "react";
import { product } from "@prisma/client";
import Image from "next/image";
import ModalPreview from "./ModalPreview";
import { ProductWithLikes } from "@/interfaces/responses/catalog.interface";
export function ProductPreview({
  product,
  setProductModal
}: {
  product: ProductWithLikes;
  setProductModal: React.Dispatch<React.SetStateAction<product | undefined>>;
}): React.JSX.Element {
  return <>
      <div key={product.id} onClick={() => setProductModal(product)} className="group ">
        <div className="sh relative w-full overflow-hidden">
          <img alt={product.title} src={product.thumbnailUrl} className="z-10 h-auto w-full object-cover object-center group-hover:brightness-75 md:mx-1 md:my-0.5" />

          <h3 className="text-md absolute inset-x-0 bottom-4 left-4 z-20 mt-4 hidden text-white  group-hover:block">
            {product.title}
          </h3>
        </div>
      </div>
    </>;
}