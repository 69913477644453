"use client";

import React from "react";
import Masonry from "react-masonry-css";
import { product } from "@prisma/client";
import { ProductPreview } from "./ProductPreview";
import { ProductWithLikes } from "@/interfaces/responses/catalog.interface";
export function LandingPageCatalogPreview({
  data,
  setProductModal
}) {
  return <Masonry breakpointCols={{
    default: 5,
    1100: 4,
    900: 3,
    800: 2,
    200: 1
  }} className="my-masonry-grid flex" columnClassName="my-masonry-grid_column " data-sentry-element="Masonry" data-sentry-component="LandingPageCatalogPreview" data-sentry-source-file="LandingPageCatalogPreview.tsx">
      {data.map((product: ProductWithLikes, i: number) => <ProductPreview key={i} product={product} setProductModal={setProductModal} />)}
    </Masonry>;
}