"use client";

import { ProductWithLikes } from "@/interfaces/responses/catalog.interface";
import { product } from "@prisma/client";
import { use, useState } from "react";
export default function LikeButton(product: ProductWithLikes) {
  const [liked, setLiked] = useState(product.liked);
  return <svg xmlns="http://www.w3.org/2000/svg" fill={liked ? "red" : "none"} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 cursor-pointer ${liked ? "text-red-500" : ""}`} onClick={() => {
    // console.log("like from ", product.uuid, product.id);

    fetch(`/api/shop/product/like`, {
      method: liked ? "DELETE" : "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        productUuid: product.uuid
      })
    });
    setLiked(!liked);
  }} data-sentry-element="svg" data-sentry-component="LikeButton" data-sentry-source-file="LikeButton.tsx">
      <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" data-sentry-element="path" data-sentry-source-file="LikeButton.tsx" />
    </svg>;
}